import {FC, useCallback, useEffect, useState} from "react";
import {
  Box,
  Button,
  CircularProgress,
  Divider,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  SelectChangeEvent,
  Stack,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import axios from "axios";
import useFetch from "../../hook/useFetch";
import Title from "../layout/Title";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import DownloadIcon from "@mui/icons-material/Download";
import CustomSnackbar, {SnackbarType} from "../layout/CustomSnackbar";
import streamSaver from "streamsaver";

declare var window: any;

interface Version {
  /* Auto incremental ID of the version */
  id: number;
  /* Automatic incremental number to identify the version of the software */
  version: number;
  /* Original filename of the software file */
  originalFilename: string;
  /* Name of the version: The couple name of the software + name of the version should be unique */
  name: string;
  /* Encrypted password with bcrypt (10 rounds) */
  password: string;
  /* Defined if an image exist for this version */
  hasImage: boolean;
  /* Defined if a file exist for this version */
  hasSoftwareFile: boolean;
}

interface Software {
  /* Auto incremental ID of the software */
  id: number;
  /* Name of the software: should be unique */
  name: string;
  /* Url to download the software */
  downloadLink: string;
  /* List of version link to the software */
  versions: Version[];
  /* Type of software  */
  type?: "vision" | "audition" | "respiration";
}

const Dashboard: FC = () => {
  const [snackbar, setSnackbar] = useState<SnackbarType>({
    severity: "error",
    message: "",
    shouldOpen: false,
  });
  const [loading, setLoading] = useState(false)
  const [loadingImage, setLoadingImage] = useState(false);
  const [loadingSoftwareFile, setLoadingSoftwareFile] = useState(false);
  const theme = useTheme();
  const matchesMD = useMediaQuery(theme.breakpoints.up("md"));
  const matchesSM = useMediaQuery(theme.breakpoints.up("sm"));
  const matchesLG = useMediaQuery(theme.breakpoints.up("lg"));
  const [data, _, __, reload] = useFetch(process.env.REACT_APP_API_ENDPOINT + "/softwares");
  const [softwares, setSoftwares] = useState<Software[]>([]);
  const [softwareVersion, setSoftwareVersion] = useState<{
    software: Software;
    version: Version;
  } | null>(null);

  useEffect(() => {
    if (data.softwares !== undefined) {
      setSoftwares(data.softwares);
    }
  }, [data]);

  const handleChange = (event: SelectChangeEvent) => {
    if (event.target.value !== undefined) {
      const arr = (event.target.value as string).split("-");
      if (arr !== undefined && arr.length === 3) {
        const software = softwares.find(
          (s) => s.id === Number(arr[0])
        ) as Software;
        const version = software.versions.find(
          (v) => v.id === Number(arr[1]) && v.version === Number(arr[2])
        ) as Version;
        setSoftwareVersion({software, version});
      }
    }
  };

  const onUploadImage = async (image: File | undefined) => {
    if (image !== undefined) {
      if (!softwareVersion) {
        setSnackbar({
          severity: "error",
          message:
            "You should select a software and a version before updating image",
          shouldOpen: !snackbar.shouldOpen,
        });
        return;
      }

      setLoadingImage(true);
      const formData = new FormData();
      formData.append("software", JSON.stringify(softwareVersion.software));
      formData.append("version", JSON.stringify(softwareVersion.version));
      formData.append("image", image);
      axios
        .post(
          process.env.REACT_APP_API_ENDPOINT + "/softwares/update/image",
          formData
        )
        .then((res) => {
          setLoadingImage(false);
          reload();
          setSnackbar({
            severity: "success",
            message: "Image saved !",
            shouldOpen: !snackbar.shouldOpen,
          });
        })
        .catch((err) => {
          setSnackbar({
            message: err.response.data.message,
            severity: "error",
            shouldOpen: !snackbar.shouldOpen,
          });
          setLoadingImage(false);
        });
    }
  };

  const onUploadSoftwareFile = async (file: File | undefined) => {
    if (file !== undefined) {
      if (!softwareVersion) {
        setSnackbar({
          severity: "error",
          message:
            "You should select a software and a version before updating file",
          shouldOpen: !snackbar.shouldOpen,
        });
        return;
      }
      setLoadingSoftwareFile(true);
      const formData = new FormData();
      formData.append("software", JSON.stringify(softwareVersion.software));
      formData.append("version", JSON.stringify(softwareVersion.version));
      formData.append("file", file);
      axios
        .post(
          process.env.REACT_APP_API_ENDPOINT + "/softwares/update/software",
          formData
        )
        .then((res) => {
          setLoadingSoftwareFile(false);
          reload();
          setSnackbar({
            severity: "success",
            message: "File saved !",
            shouldOpen: !snackbar.shouldOpen,
          });
        })
        .catch((err) => {
          setSnackbar({
            message: err.response.data.message,
            severity: "error",
            shouldOpen: !snackbar.shouldOpen,
          });
          setLoadingSoftwareFile(false);
        });
    }
  };

  const onUploadSoftwaresJSON = async (file: File | undefined) => {
    if (file !== undefined) {
      const promise = new Promise<string>((resolve, reject) => {
        const reader = new FileReader();

        reader.onload = function (event) {
          resolve(event.target?.result as string);
        };

        reader.onerror = () => reject("Error while reading image as data URL.");
        reader.readAsText(file);
      });
      try {
        const data = JSON.parse(await promise);
        axios
          .post(
            process.env.REACT_APP_API_ENDPOINT + "/softwares/update-json",
            data
          )
          .then((res) => {
            setLoading(false);
            setSoftwares(res.data.softwares);
            setSnackbar({
              severity: "success",
              message: "JSON saved !",
              shouldOpen: !snackbar.shouldOpen,
            });
          })
          .catch((err) => {
            setSnackbar({
              message: err.response.data.message,
              severity: "error",
              shouldOpen: !snackbar.shouldOpen,
            });
            setLoading(false);
          });
      } catch (e) {
        setSnackbar({
          severity: "error",
          message: "Document can't be parse as JSON.",
          shouldOpen: !snackbar.shouldOpen,
        });
      }
    }
  };

  const handleDownloadSoftware = useCallback(() => {
    if (softwareVersion === null) return;

    const url = `${process.env.REACT_APP_API_ENDPOINT}/softwares/${softwareVersion.software.name}/${softwareVersion.version.name}/software?secretToken=${process.env.REACT_APP_SECRET_TOKEN_SOFTWARE}`;

    fetch(url)
      .then((res: Response) => {
        if (!res || !res.body || !res.headers) return;

        const contentDisposition = res.headers.get("content-disposition") ?? "";
        const fileStream = streamSaver.createWriteStream(
          contentDisposition.split('filename="')[1].slice(0, -1)
        );
        const readableStream = res.body;

        // more optimized
        if (window.WritableStream && readableStream.pipeTo) {
          return readableStream
            .pipeTo(fileStream)
            .then(() => console.log("done writing"));
        }

        const writer = fileStream.getWriter();

        const reader = res.body?.getReader();
        if (!!reader) {
          const pump: () => void = () =>
            reader
              .read()
              .then((res) =>
                res.done ? writer.close() : writer.write(res.value).then(pump)
              );

          pump();
        }
      })
      .catch((err) => {
        setSnackbar({
          severity: "error",
          message: err.message,
          shouldOpen: !snackbar.shouldOpen,
        });
      });
  }, [softwareVersion]);

  return (
    <>
      <CustomSnackbar {...snackbar} />
      <Title matchesLG={matchesLG} matchesMD={matchesMD} matchesSM={matchesSM}>
        Dashboard
      </Title>
      <Box sx={{flexGrow: 1, p: matchesLG ? 8 : 2}}>
        <Paper>
          <Stack
            sx={matchesLG ? {px: 4, py: 8} : {p: 2}}
            direction={matchesLG ? "row" : "column"}
            justifyContent="space-around"
            alignItems="stretch"
            spacing={matchesLG ? 4 : 2}
            divider={
              <Divider
                orientation={matchesLG ? "vertical" : "horizontal"}
                flexItem
              />
            }
          >
            <IconButton
              color="primary"
              aria-label="upload JSON"
              component="label"
              sx={{
                borderWidth: "2px",
                borderStyle: "solid",
                borderRadius: "10px",
                minWidth: "175px",
                minHeight: "400px",
                fontSize: "48px",
                flex: 1,
                m: 2,
              }}
            >
              <input
                hidden
                accept="application/json"
                type="file"
                onChange={(event) =>
                  onUploadSoftwaresJSON(event.target.files?.[0])
                }
              />
              {loading ? (
                <CircularProgress/>
              ) : (
                <FileUploadIcon sx={{fontSize: "72px"}}/>
              )}
            </IconButton>
            <Grid
              container
              direction={matchesLG ? "row" : "column"}
              alignItems={matchesLG ? "center" : "flex-start"}
              spacing={2}
              sx={{maxWidth: "calc(100% - 32px)", flex: 2}}
            >
              <Grid item xs={12} md={7} xl={6}>
                Choississez le logiciel et sa version
              </Grid>
              <Grid item xs={12} md={5} xl={6} container direction="row">
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">
                    Logiciels
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={
                      softwareVersion?.software.id +
                      "-" +
                      softwareVersion?.version.id +
                      "-" +
                      softwareVersion?.version.version
                    }
                    label="Logiciels"
                    onChange={handleChange}
                  >
                    {
                      softwares?.map((software) =>
                        software.versions.map((version) => (
                          <MenuItem
                            value={software.id + "-" + version.id + "-" + version.version}
                            key={software.id + "-" + version.id + "-" + version.version}
                          >
                            {version.hasSoftwareFile ? null : (
                              <AttachFileIcon color="error" fontSize="small" sx={{mr: 1}}/>
                            )}
                            {software.name + " " + version.name}
                          </MenuItem>
                        ))
                      )
                        .flat()
                    }
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={7} xl={6}>
                Choisissez le fichier du logiciel à uploader
              </Grid>
              <Grid item xs={12} md={5} xl={6}>
                {loadingSoftwareFile ? (
                  <CircularProgress/>
                ) : (
                  <>
                    <Button
                      variant="contained"
                      component="label"
                      disabled={softwareVersion === null}
                    >
                      Télécharger
                      <input
                        name="file"
                        hidden
                        multiple
                        type="file"
                        onChange={(event) =>
                          onUploadSoftwareFile(event.target.files?.[0])
                        }
                      />
                    </Button>
                    {softwareVersion !== null &&
                    softwareVersion.version.hasSoftwareFile ? (
                      <Button
                        sx={{ml: 2}}
                        variant="outlined"
                        component="label"
                        onClick={handleDownloadSoftware}
                      >
                        <DownloadIcon
                          fontSize="small"
                          sx={{mr: 1}}
                        ></DownloadIcon>
                        {softwareVersion.version.originalFilename.length > 0
                          ? softwareVersion.version.originalFilename
                          : "Fichier"}
                      </Button>
                    ) : null}
                  </>
                )}
              </Grid>
            </Grid>
          </Stack>
        </Paper>
      </Box>
    </>
  );
};

export default Dashboard;
